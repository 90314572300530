.message {
    color: #6c757d;
    /* font-size: 16px; */
    /* letter-spacing: -2px; */
}

/* .message-img {
  height: 200px;
  width: 200px;
  object-fit: cover;
} */

.payment-gateway {
    height: 100px;
    width: 60%;
    object-fit: contain;
}

.message-image-profile {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: relative;
    border-radius: 5px 0 0 5px;
}

.about-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.news-images {
    width: 40%;
    height: auto;
    object-fit: cover;
}

.message-image {
    width: 100%;
    height: auto;
    object-fit: contain;
}
