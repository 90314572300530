*,
*::before,
*::after {
  box-sizing: border-box;
}
.page-heading {
    text-align: center;
    margin: 10px;
    padding: 10px;
}

.heading-text {
    font-size: 25px;
    font-weight: 600;
    color: #eb5d1e;
}

.blogImage img{
    width: 100%;
    height: 60vh;
    object-fit: cover;
    border-radius: 3px;
}

.contactIcon{
    color: #eb5d1e;
    font-size: 24px;
}

.message-image, .message-text{
    min-height: 300px;
    background: #f5f4f0 !important;
}

.outstanding-header .card, .water-schedule-detail{
    padding: 0 !important;
}
.water-schedule-detail-table{
    margin-bottom: 0 !important;
}

.water-schedule-detail-card-header-one{
    margin-bottom: 4px !important;
    /*background: rgba(0, 128, 128, .8);*/
    /*background: rgba(5, 195, 221, .1);*/
    /*color: white;*/
}
.water-schedule-detail-card-header-two{
    margin-bottom: 0 !important;
    /*background: rgba(0, 128, 128, .1);*/
    /*color: white;*/
}
/*.top_bar-wrapper, .top_bar-wrapper a{*/
/*    color: red !important;*/
/*}*/
/*.down_bar-wrapper, .down_bar-wrapper a{*/
/*    color: red !important;*/
/*}*/
.ftco-footer{
    padding-top: 2rem;
}
/*.footer-wrapper, .footer-wrapper a, .footer-wrapper h1, .footer-wrapper h2, .footer-wrapper h3{*/
/*    color: red !important;*/
/*}*/
