.swiper {
    width: 100%;
    height: 80vh;
    /* background-image: linear-gradient(to right, #1d2227, #2b2d4f, #622962, #9f044f, #c01717); */
    /* background-image: linear-gradient(to right, #1d2227, #2e353c, #414952, #545f69, #687581); */
    /* filter: blur(8px); */
  }
  
  .swiper-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: blur(0px);
    position: absolute;
    z-index: 9;
  }
  .swiper-img-bg{
    width: 100%;
    height: 100%;
    filter: blur(30px);
    object-fit: cover;
    position: absolute;
  }
  /* .swiper-slide-width {
    width: 300px;
    height: 300px;
  } */
  
  @media only screen and (max-width: 600px) {
    .swiper-button-next, .swiper-button-prev, .swiper-pagination-bullet {
      display: none;
    }
  }
  