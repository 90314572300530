.team-image {
  width: 100px;
  height: 100px;
}

.board-section {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.team {
  background-color: white;
}
.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(16, 110, 234, 0.15);
}
.team .member .member-img {
  position: relative;
  overflow: hidden;
  text-align: center;
}
.team .member .member-img img {
  max-height: 200px;
  object-fit: cover;
  width: 60%;
  cursor: pointer;
  transition: 0.8s;
  height: 200px;
}
.member {
  margin-top: 10px;
}
.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #0086ff;
  text-align: center;
}
.team .member .member-info span {
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: black;
  text-align: center;
}
.staff-name {
  color: black;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 5px;
}

.staff-designation {
  font-size: 14px;
  font-weight: 400;
}

.card-header {
  margin-bottom: 15px;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .card-header{
    margin: 0;
}

/* .team .card-body{
    padding: 0;
    margin-bottom: 5px;
} */

.team .board-members{
    padding: 0;
}
