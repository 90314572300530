.logo-img {
  border-radius: 50%;
  max-width: 50px;
  max-height: 50px;
  object-fit: contain;
  background-color: white;
}

/* .dropdown-item {
  color: #eb5d1e;
  font-weight: bold;
} */

.dropdown-item {
  font-weight: normal;
}

.slogan {
  font-size: 15px;
  margin-left: 12px;
  font-weight: normal;
}

.fixed-top{
  background: black;
}
