.custom-modal-backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 998;
}

.custom-modal {
    background-color: white;
    margin: auto;
    height:500px;
    vertical-align: middle;
    top: 6%;
}

.modal-card-body{
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.custom-header{
    justify-content: flex-end;
}

.f-left {
    float: left;
}

.notice-img {
    height: 100%;
    width: 100%;
}

